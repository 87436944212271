<!--<template>-->
<!--  <div>-->
<!--    &lt;!&ndash; 面包屑导航 &ndash;&gt;-->
<!--    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">-->
<!--      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>数据清洗标准</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>{{crumbA}}</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->
<!--    <over-view-of-coal :table-data="tableData" :total="total" v-if="overViewFlag"></over-view-of-coal>-->
<!--    <br>-->
<!--    <span style="font-size: 10px;">搜索：</span>-->
<!--    <el-select v-model="currentCoalId"-->
<!--               placeholder="请选择"-->
<!--               @change="handleSearch"-->
<!--               clearable-->
<!--               style="width: 400px">-->
<!--      <el-option v-for="item in coaloptions" :key="item.id" :label="item.coal" :value="item.id"></el-option>-->
<!--    </el-select>-->
<!--    <br>-->

<!--    <el-table-->
<!--        :data="tableData"-->
<!--        style="min-width: 100%"-->
<!--        border-->
<!--        v-loading="dataListLoading"-->
<!--    >-->
<!--      <el-table-column-->
<!--          label="序号"-->
<!--          type="index"-->
<!--          min-width="5%"-->
<!--          align="center">-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{(page - 1) * size + scope.$index + 1}}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="id"-->
<!--          label="系统id"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="5%"-->
<!--          v-if="false"-->
<!--      >-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="name"-->
<!--          label="系统名称"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="10%" >-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="subSystemCount"-->
<!--          label="子系统个数"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="10%">-->
<!--      </el-table-column>-->
<!--      &lt;!&ndash;      <el-table-column&ndash;&gt;-->
<!--      &lt;!&ndash;          prop="datasize"&ndash;&gt;-->
<!--      &lt;!&ndash;          label="数据量"&ndash;&gt;-->
<!--      &lt;!&ndash;          header-align="center"&ndash;&gt;-->
<!--      &lt;!&ndash;          align="center"&ndash;&gt;-->
<!--      &lt;!&ndash;          min-width="10%">&ndash;&gt;-->
<!--      &lt;!&ndash;      </el-table-column>&ndash;&gt;-->
<!--      <el-table-column-->
<!--          prop="createdate"-->
<!--          label="创建时间"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="10%">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          label="操作"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="10%">-->
<!--        <template slot-scope="scope">-->
<!--          &lt;!&ndash;          <el-button&ndash;&gt;-->
<!--          &lt;!&ndash;              type="text"&ndash;&gt;-->
<!--          &lt;!&ndash;              size="small"&ndash;&gt;-->
<!--          &lt;!&ndash;              @click="handleCheck(scope.row)"&ndash;&gt;-->
<!--          &lt;!&ndash;              :disabled="scope.row.subSystemCount == 0?true:false"&ndash;&gt;-->
<!--          &lt;!&ndash;          >查看</el-button>  &lt;!&ndash; 这里要改，当子系统个数为0时，查看按钮不可用 &ndash;&gt;&ndash;&gt;-->
<!--          <el-button-->
<!--              type="text"-->
<!--              size="small"-->
<!--              @click="handleCheck(scope.row)"-->
<!--          >查看</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="remark"-->
<!--          label="备注"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="20%">-->
<!--      </el-table-column>-->

<!--    </el-table>-->

<!--    &lt;!&ndash;分页区域&ndash;&gt;-->

<!--    <div style="display: flex;justify-content: flex-end">-->
<!--      <el-pagination-->
<!--          background-->
<!--          @current-change="currentChange"-->
<!--          @size-change="sizeChange"-->
<!--          :current-page="page"-->
<!--          :page-size="size"-->
<!--          layout="sizes, prev, pager, next, jumper, ->, total, slot"-->
<!--          :total="total">-->
<!--      </el-pagination>-->
<!--    </div>-->
<!--    &lt;!&ndash; <checkDataquality v-if="checkVisible" ref="checkquality"></checkDataquality> &ndash;&gt;-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import OverViewOfCoal from "../coalSystemManage/overViewOfCoal";-->
<!--export default {-->
<!--  name: "dataQualitySystem",-->
<!--  components: {OverViewOfCoal},-->
<!--  data() {-->
<!--    return {-->
<!--      currentCoal:"",-->
<!--      coaloptions: [],-->
<!--      currentCoalId: '',-->
<!--      tableData:[],-->
<!--      page:1,  //当前第几页-->
<!--      size:10, //当前每页个数-->
<!--      total:0, //全部数据行数-->

<!--      currOperator: '',-->
<!--      currOperatorName: '',-->

<!--      dataListLoading: false,-->
<!--      overViewFlag: false,-->

<!--      crumbA: '',-->
<!--      sizeFromSet: 10,-->
<!--    }-->
<!--  },-->
<!--  mounted(){-->
<!--    this.currOperator = JSON.parse(-->
<!--        window.sessionStorage.getItem("user")-->
<!--    ).userId;-->
<!--    this.currOperatorName = JSON.parse(-->
<!--        window.sessionStorage.getItem("user")-->
<!--    ).username;-->

<!--    if(this.global.coal_id === 0 && this.global.coal_name === '') {-->
<!--      this.initData()-->
<!--    } else {-->
<!--      this.initDataWithGlobalVar()-->
<!--    }-->
<!--  },-->
<!--  methods: {-->
<!--    // 监听 pagesize 改变的事件-->
<!--    currentChange(currentPage){-->
<!--      this.page=currentPage;-->
<!--      this.initDataWithGlobalVar();-->
<!--    },-->
<!--    sizeChange(currentSize) {-->
<!--      this.size = currentSize;-->
<!--      this.initDataWithGlobalVar();-->
<!--    },-->
<!--    resetSizePage() {-->
<!--      this.size = this.sizeFromSet-->
<!--      this.page = 1-->
<!--    },-->
<!--    handleCheck(row) {-->
<!--      this.global.system_id = row.id-->
<!--      this.global.system_name = row.name-->
<!--      this.$router.push({-->
<!--        path: '/dataQualitySubsystem',-->
<!--      });-->
<!--      // this.$router.push({-->
<!--      //   path: '/dataQualitySubsystem',-->
<!--      //   query: {-->
<!--      //     dataQualitySystemId: row.id,-->
<!--      //     crumb: [this.currentCoal, row.name]-->
<!--      //   }-->
<!--      // });-->
<!--    },-->
<!--    initData(){-->
<!--      this.dataListLoading = true;-->
<!--      this.getRequest("/dataList/getAllCoal/").then((resp) => {-->
<!--        if (resp.data) {-->
<!--          this.coaloptions = resp.data;-->
<!--          if (this.coaloptions.length != 0) {-->
<!--            this.currentCoalId = this.coaloptions[0].id-->
<!--            this.currentCoal = this.coaloptions[0].coal-->

<!--            // 处理全局变量-->
<!--            this.$changeGlobalVar(this.global.MODE_COAL, this.currentCoalId, this.currentCoal)-->

<!--            // 处理面包屑-->
<!--            this.crumbA = this.global.coal_name-->
<!--          }-->
<!--        }-->
<!--      }).then(() => {-->
<!--        this.getRequest(-->
<!--            "/coalSystemManage/getSystemInfoByCoalId/?coalId=" +-->
<!--            this.currentCoalId +-->
<!--            "&page=" +-->
<!--            this.page +-->
<!--            "&size=" +-->
<!--            this.size-->
<!--        ).then((resp) => {-->
<!--          if (resp.data) {-->
<!--            this.tableData = resp.data.data;-->
<!--            this.total = resp.data.total;-->
<!--            this.overViewFlag = true;-->
<!--          }-->
<!--          this.dataListLoading = false-->
<!--        });-->
<!--      });-->
<!--    },-->
<!--    initDataWithGlobalVar() {-->
<!--      this.dataListLoading = true-->
<!--      this.getRequest("/dataList/getAllCoal/").then((resp) => {-->
<!--        if (resp.data) {-->
<!--          this.coaloptions = resp.data;-->
<!--          this.currentCoalId = this.global.coal_id-->
<!--          this.currentCoal = this.global.coal_name-->
<!--          // 处理面包屑-->
<!--          this.crumbA = this.global.coal_name-->
<!--        }-->
<!--      }).then(() => {-->
<!--        this.getRequest(-->
<!--            "/coalSystemManage/getSystemInfoByCoalId/?coalId=" +-->
<!--            this.currentCoalId +-->
<!--            "&page=" +-->
<!--            this.page +-->
<!--            "&size=" +-->
<!--            this.size-->
<!--        ).then((resp) => {-->
<!--          if (resp.data) {-->
<!--            this.tableData = resp.data.data;-->
<!--            this.total = resp.data.total;-->
<!--            this.overViewFlag = true;-->
<!--          }-->
<!--          this.dataListLoading = false-->
<!--        })-->
<!--      })-->
<!--    },-->
<!--    handleSearch() {-->
<!--      this.resetSizePage()-->
<!--      this.dataListLoading = true-->
<!--      if (this.currentCoalId === '') {-->
<!--        this.currentCoal = this.global.coal_name-->
<!--        this.getRequest(-->
<!--            "/coalSystemManage/getSystemInfoByCoalId/?coalId=" +-->
<!--            this.global.coal_id +-->
<!--            "&page=" +-->
<!--            this.page +-->
<!--            "&size=" +-->
<!--            this.size-->
<!--        ).then((resp) => {-->
<!--          if (resp.data) {-->
<!--            this.tableData = resp.data.data;-->
<!--            this.total = resp.data.total;-->
<!--            this.overViewFlag = true;-->
<!--          }-->
<!--          this.dataListLoading = false-->
<!--        })-->
<!--      } else {-->
<!--        let obj = {}-->
<!--        obj = this.coaloptions.find((item)=>{-->
<!--          return item.id === this.currentCoalId; // 筛选出匹配数据-->
<!--        });-->
<!--        this.currentCoal = obj.coal-->

<!--        // 处理全局变量-->
<!--        this.$changeGlobalVar(this.global.MODE_COAL, this.currentCoalId, this.currentCoal)-->

<!--        // 处理面包屑-->
<!--        this.crumbA = this.global.coal_name-->
<!--        console.log(this.crumbA)-->

<!--        this.getRequest(-->
<!--            "/coalSystemManage/getSystemInfoByCoalId/?coalId=" +-->
<!--            this.currentCoalId +-->
<!--            "&page=" +-->
<!--            this.page +-->
<!--            "&size=" +-->
<!--            this.size-->
<!--        ).then((resp) => {-->
<!--          if (resp.data) {-->
<!--            this.tableData = resp.data.data;-->
<!--            this.total = resp.data.total;-->
<!--            this.overViewFlag = true;-->
<!--          }-->
<!--          this.dataListLoading = false-->
<!--        });-->
<!--      }-->
<!--    },-->
<!--    getAllSystemInfoByCoalId() {-->
<!--      this.dataListLoading = true-->
<!--      if (this.currentCoalId != '') {-->
<!--        let obj = {}-->
<!--        obj = this.coaloptions.find((item)=>{-->
<!--          return item.id === this.currentCoalId; // 筛选出匹配数据-->
<!--        });-->
<!--        this.currentCoal = obj.coal-->
<!--        this.getRequest(-->
<!--            "/coalSystemManage/getSystemInfoByCoalId/?coalId=" +-->
<!--            this.currentCoalId +-->
<!--            "&page=" +-->
<!--            this.page +-->
<!--            "&size=" +-->
<!--            this.size-->
<!--        ).then((resp) => {-->
<!--          if (resp.data) {-->
<!--            this.tableData = resp.data.data;-->
<!--            this.total = resp.data.total;-->
<!--            this.overViewFlag = true;-->
<!--          }-->
<!--          this.dataListLoading = false-->
<!--        });-->
<!--      } else {-->
<!--        this.currentCoal = ''-->
<!--        this.getRequest(-->
<!--            "/coalSystemManage/getCoalSystemInfoByPage/?page=" +-->
<!--            this.page +-->
<!--            "&size=" +-->
<!--            this.size + "&userId=" + this.currOperator-->
<!--        ).then((resp) => {-->
<!--          if (resp) {-->
<!--            this.tableData = resp.data.data;-->
<!--            this.total = resp.data.total;-->
<!--            this.overViewFlag = true;-->
<!--          }-->
<!--          this.dataListLoading = false-->
<!--        });-->
<!--      }-->
<!--    },-->
<!--  }-->
<!--}-->
<!--</script>-->

<!--<style>-->

<!--</style>-->

<!--原来代码-->


<template>
  <div>
    <div class="mod-config">
      <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>数据质量管理</el-breadcrumb-item>
      </el-breadcrumb>
      <el-form :inline="true" :model="dataForm">
        <!--    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">-->
        <el-form-item>
          <el-input v-model="dataForm.key" placeholder="项目名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <!--        <el-button type="primary" @click="setAttrabute()" :disabled="projectEnabled===1">设置项目属性</el-button>-->
          <!--        <el-button type="danger" @click="resetAttrabute()" :disabled="projectEnabled===0">重置项目属性</el-button>-->
          <el-button @click="selectByNameAndInit()" :disabled="projectEnabled===0">查询</el-button>
          <el-button @click="init()" :disabled="projectEnabled===0">重置</el-button>
          <!--        <el-button type="primary" @click="addOrUpdateHandle()" :disabled="projectEnabled===0">新增</el-button>-->
          <!--        <el-button  type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0 && projectEnabled===0">批量删除</el-button>-->
        </el-form-item>
      </el-form>
      <el-table :data="tableData" border width="80%" @selection-change="selectionChangeHandle">
        <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50">
        </el-table-column>
        <template v-for="(item,index) in tableCols">
          <el-table-column :prop="item.cols"
                           :label="item.name"
                           :key="index">
          </el-table-column>
        </template>
        <el-table-column
            fixed="right"
            header-align="center"
            align="center"
            width="200"
            label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small "@click="checkHandle(scope.row.id,scope.row.hbaseTableName)" >查看</el-button>
            <!--          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)" >修改</el-button>-->
            <!--          <el-button type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <project-attribute-add v-if="projectAttributeAddVisible" ref="projectAttributeAdd" @refreshDataList="init"></project-attribute-add>
      <project-add-or-update v-if="addOrUpdateVisible" ref="ProjectAddOrUpdate" @refreshDataList="init"></project-add-or-update>
      <!--    <create-table v-if="createTablevisible" ref="createTable" @refreshDataList="init"></create-table>-->
    </div>
    <!--    <el-tabs v-model="activeName" type="card">-->
    <!--      <el-tab-pane label="表格信息" name="chart">-->
    <!--        <chart v-if="activeName == 'chart'"></chart>-->
    <!--      </el-tab-pane>-->
    <!--      <el-tab-pane label="图表信息" name="graph">-->
    <!--        <graph v-if="activeName == 'graph'"></graph>-->
    <!--      </el-tab-pane>-->
    <!--    </el-tabs>-->
  </div>
</template>

<script>
import ProjectAttributeAdd from "/src/views/modules/levelManage/project-attribute-add";
import ProjectAddOrUpdate from "/src/views/modules/levelManage/project-attribute-add.vue";
export default {
  name: "projectManage",
  components: {ProjectAddOrUpdate, ProjectAttributeAdd},
  data () {
    return {
      activeName: 'chart',
      show: false,
      visible: false,
      projectEnabled: false,
      dataForm: {
        key: ''
      },
      dataListSelections: [],
      tableData: [],
      tableCols: [],
      isLeaf: 0,
      tableId: 0,
      itemId: 0,
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      addOrUpdateVisible: false,
      projectAttributeAddVisible: false,
      enterState: 0,   //进入状态，0代表直接进入，2代表搜索进入
    }
  },
  componets:{
    ProjectAttributeAdd,
    ProjectAddOrUpdate,
  },
  methods: {
    checkHandle(id,hbaseTableName){
      //id为第一层id
      this.$http({
        url: this.$http.adornUrl('/levelManage/projectattributes/projectToLevel?id='+id),
        method: 'get',
      }).then(({data}) => {
        if (data && data.code === 0) {
          console.log(data)
          if(data.state===0){
            this.$message({
              message: '当前项目没有绑定层级关系',
              type: 'success',
            })
          }
          else {
            window.sessionStorage.setItem("enterState",0)
            window.sessionStorage.setItem("tableId",data.tableId)//传入的tableid为第二层表的id

            window.sessionStorage.setItem("projectId",id)
            window.sessionStorage.setItem("hbaseTableName",hbaseTableName)

            this.$router.push({
              // name: 'universal-table'
              name: 'universal-table-dataQuality'
            })
          }
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    resetAttrabute(){
      this.$confirm(`确定对对项目属性进行重置？这会导致已有的项目数据被清空`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/levelManage/projectattributes/dropProject'),
          method: 'get',
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.tableCols=[]
                this.init()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    getDataList(){
      this.$http({
        url: this.$http.adornUrl('/levelManage/projectattributes/hasProject'),
        method: 'get',
      }).then(({data}) =>{
        this.projectEnabled=data.count;
        return this.projectEnabled
      }).then(()=>{
        if(this.projectEnabled===1){
          this.$http({
            url: this.$http.adornUrl('/levelManage/projectattributes/getCols'),
            method: 'get',
          }).then(({data})=>{
            this.tableCols=[]
            for(let i=0;i<data.data.length;i++)
            {
              this.tableCols.push({
                // {
                //   "name": "create_time",
                //     "remark": "创建时间",
                //     "type": "date"
                // },
                //中文列名
                name: data.data[i].remark,
                //存在tabledata里的key
                cols: data.data[i].name,
              })
            }
          }).then(()=>{
            this.$http({
              url: this.$http.adornUrl('/levelManage/projectattributes/getProjectInfo?page='+this.pageIndex+'&size='+this.pageSize  ),
              method: 'get',
            }).then(({data})=>{
              this.tableData=data.data;
              this.totalPage=data.count;
            })
          })
        }
      })
    },
    init(){
      this.enterState=0
      this.getDataList()
    },
    setAttrabute(){
      this.projectAttributeAddVisible=true
      this.$nextTick(() => {
        this.$refs.projectAttributeAdd.init()
      })
    },
    selectByName() {
      this.enterState=2
      window.sessionStorage.setItem("enterState",this.enterState)
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/levelManage/projectattributes/selectByName?name='+this.dataForm.key+'&page='+this.pageIndex+'&limit='+this.pageSize),
        method: 'get',
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.tableData=data.data;
          this.totalPage = data.count
          this.showpage=true;
        } else {
          this.dataList = []
          this.totalPage = 0
          this.showpage=true;
        }
        this.dataListLoading = false
      })
    },
    selectByNameAndInit()
    {
      this.pageIndex=1
      this.selectByName()
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      if(this.enterState===0||this.enterState==='0')
      {
        this.init()
      }
      else
      {
        this.selectByNameAndInit()
      }
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      if(this.enterState===0||this.enterState==='0')
      {
        this.init()
      }
      else
      {
        this.selectByName()
      }
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.ProjectAddOrUpdate.init(id)
      })
    },
    // 删除
    deleteHandle (id) {
      var ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?这也会解除${id ? '它' : '它们'}和层级间的关系`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/levelManage/projectattributes/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped>

</style>
